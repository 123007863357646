import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VCard,[_c(VRow,{staticClass:"mx-2 pt-2"},[_c(VCol,[_c('h3',[_vm._v("Riwayat Integrasi Tokopedia")])])],1),_c(VDivider,{staticClass:"my-4"}),_c('div',{staticClass:"px-4 pb-4"},[_c(VRow,{staticClass:"mb-1"},[_c(VCol,{attrs:{"cols":"12","md":"4"}},[_c(VTextField,{staticClass:"w-100",attrs:{"outlined":"","dense":"","label":"Cari user","hide-details":"auto"},on:{"change":_vm.searchToken},model:{value:(_vm.tokenFilter.search),callback:function ($$v) {_vm.$set(_vm.tokenFilter, "search", $$v)},expression:"tokenFilter.search"}})],1),_c(VCol,{attrs:{"cols":"12","md":"4"}},[_c(VSelect,{staticClass:"w-25",attrs:{"items":_vm.statusOptions,"item-text":"label","item-value":"id","label":"Sort","outlined":"","dense":"","return-object":"","hide-details":"auto"},on:{"change":_vm.changeStatusToken},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center justify-space-between flex-grow-1"},[_c('div',{staticClass:"font-weight-bold"},[_c(VIcon,{staticClass:"me-1",attrs:{"size":"26"}},[_vm._v(" "+_vm._s(item.icon)+" ")]),_c('span',{staticClass:"font-weight-semibold"},[_vm._v(" "+_vm._s(item.label))])],1)])]}}]),model:{value:(_vm.tokenFilter.status),callback:function ($$v) {_vm.$set(_vm.tokenFilter, "status", $$v)},expression:"tokenFilter.status"}})],1)],1)],1),_c(VDataTable,{attrs:{"headers":_vm.headers,"items":_vm.TokenRequestListTokopedia,"items-per-page":10,"item-key":"id","disable-sort":"","footer-props":{
        'items-per-page-options': [10, 25, 50],
        'items-per-page-text': 'Jumlah data',
      },"server-items-length":_vm.TokenRequestCountTokopedia,"mobile-breakpoint":"0","loading":_vm.loadingTokenRequestTokopedia,"options":_vm.tokenPagination},on:{"update:options":function($event){_vm.tokenPagination=$event}},scopedSlots:_vm._u([{key:"item.created_at",fn:function(props){return [_c('span',[_vm._v(_vm._s(_vm.formatDate(props.item.created_at, { month: 'short', day: 'numeric', year: 'numeric', hour: 'numeric', minute: 'numeric' })))])]}},{key:"item.workspace",fn:function(ref){
      var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_c(VAvatar,{staticClass:"v-avatar-light-bg primary--text",attrs:{"size":"40px","color":"primary"}},[_c('span',[_vm._v(" "+_vm._s(_vm.avatarText(item.workspace.name))+" ")])]),_c('span',{staticClass:"text-subtitle-2 ms-2"},[_vm._v(_vm._s(item.workspace.name))])],1)]}},{key:"item.connection_name",fn:function(props){return [_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(props.item.connection_name))])]}},{key:"item.shop_url",fn:function(props){return [_c('span',[_c('a',{staticClass:"font-weight-bold",attrs:{"href":props.item.shop_url,"target":"_blank"}},[_vm._v(_vm._s(props.item.shop_url)+" ")])])]}},{key:"item.status",fn:function(props){return [_c(VChip,{attrs:{"color":props.item.status ? 'success' : 'warning',"small":"","outlined":""}},[_vm._v(" "+_vm._s(props.item.status ? 'Added' : 'Pending')+" ")])]}},{key:"item.action",fn:function(props){return [(!props.item.status)?_c(VBtn,{attrs:{"color":"primary","small":""},on:{"click":function($event){return _vm.confirmChangeStatus(props.item.id)}}},[_vm._v(" Konfirmasi ")]):_vm._e(),(!props.item.status)?_c(VBtn,{staticClass:"ml-2",attrs:{"color":"error","small":""},on:{"click":function($event){return _vm.confirmDeleteToken(props.item.id, props.item.workspace.id)}}},[_vm._v(" Tolak ")]):_vm._e()]}}],null,true)})],1),_c('tokopedia-confirm-add',{ref:"tokopediaConfirmAddDialog",on:{"success":function($event){return _vm.fetchRequestList(true)}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }