<template>
  <div>
    <v-card>
      <v-row class="mx-2 pt-2">
        <v-col>
          <h3>Riwayat Integrasi Tokopedia</h3>
        </v-col>
      </v-row>
      <v-divider class="my-4" />
      <div class="px-4 pb-4">
        <v-row class="mb-1">
          <v-col
            cols="12"
            md="4"
          >
            <v-text-field
              v-model="tokenFilter.search"
              outlined
              dense
              label="Cari user"
              class="w-100"
              hide-details="auto"
              @change="searchToken"
            />
          </v-col>
          <v-col
            cols="12"
            md="4"
          >
            <v-select
              v-model="tokenFilter.status"
              :items="statusOptions"
              item-text="label"
              item-value="id"
              label="Sort"
              outlined
              dense
              class="w-25"
              return-object
              hide-details="auto"
              @change="changeStatusToken"
            >
              <template v-slot:item="{ item }">
                <div class="d-flex align-center justify-space-between flex-grow-1">
                  <div class="font-weight-bold">
                    <v-icon
                      class="me-1"
                      size="26"
                    >
                      {{ item.icon }}
                    </v-icon>
                    <span class="font-weight-semibold"> {{ item.label }}</span>
                  </div>
                </div>
              </template>
            </v-select>
          </v-col>
        </v-row>
      </div>
      <v-data-table
        :headers="headers"
        :items="TokenRequestListTokopedia"
        :items-per-page="10"
        item-key="id"
        disable-sort
        :footer-props="{
          'items-per-page-options': [10, 25, 50],
          'items-per-page-text': 'Jumlah data',
        }"
        :server-items-length="TokenRequestCountTokopedia"
        mobile-breakpoint="0"
        :loading="loadingTokenRequestTokopedia"
        :options.sync="tokenPagination"
      >
        <template
          v-slot:[`item.created_at`]="props"
        >
          <span>{{ formatDate(props.item.created_at, { month: 'short', day: 'numeric', year: 'numeric', hour: 'numeric', minute: 'numeric' }) }}</span>
        </template>
        <template v-slot:[`item.workspace`]="{ item }">
          <div class="d-flex align-center">
            <v-avatar
              size="40px"
              color="primary"
              class="v-avatar-light-bg primary--text"
            >
              <span>
                {{ avatarText(item.workspace.name) }}
              </span>
            </v-avatar>
            <span class="text-subtitle-2 ms-2">{{ item.workspace.name }}</span>
          </div>
        </template>
        <template
          v-slot:[`item.connection_name`]="props"
        >
          <span class="font-weight-bold">{{ props.item.connection_name }}</span>
        </template>
        <template
          v-slot:[`item.shop_url`]="props"
        >
          <span>
            <a
              class="font-weight-bold"
              :href="props.item.shop_url"
              target="_blank"
            >{{ props.item.shop_url }}
            </a>
          </span>
        </template>
        <template
          v-slot:[`item.status`]="props"
        >
          <v-chip
            :color="props.item.status ? 'success' : 'warning'"
            small
            outlined
          >
            {{ props.item.status ? 'Added' : 'Pending' }}
          </v-chip>
        </template>
        <template v-slot:[`item.action`]="props">
          <v-btn
            v-if="!props.item.status"
            color="primary"
            small
            @click="confirmChangeStatus(props.item.id)"
          >
            Konfirmasi
          </v-btn>
          <v-btn
            v-if="!props.item.status"
            color="error"
            small
            class="ml-2"
            @click="confirmDeleteToken(props.item.id, props.item.workspace.id)"
          >
            Tolak
          </v-btn>
        </template>
      </v-data-table>
    </v-card>
    <tokopedia-confirm-add
      ref="tokopediaConfirmAddDialog"
      @success="fetchRequestList(true)"
    />
  </div>
</template>
<script>
import useTokopedia from '@/composables/useTokopedia'
import { onMounted, ref, watch } from '@vue/composition-api'
import { formatDate, avatarText } from '@core/utils/filter'
import Vue from 'vue'
import useIntegration from '@/composables/useIntegration'
import TokopediaConfirmAdd from './forms/TokopediaConfirmAdd.vue'

export default {
  components: {
    TokopediaConfirmAdd,
  },
  setup() {
    const tokopediaConfirmAddDialog = ref(null)
    const {
      fetchTokopediaIntegrateRequests, loadingTokenRequestTokopedia, TokenRequestListTokopedia, TokenRequestCountTokopedia,
      // changeStatusTokopediaToken, loadingChangeStatusTokopediaToken,
    } = useTokopedia()

    const { removeWorkspaceToken, loadingDeleteToken } = useIntegration()
    const headers = [
      {
        text: 'Tanggal Dibuat',
        value: 'created_at',
      },
      {
        text: 'Workspace',
        value: 'workspace',
      },
      {
        text: 'Nama Toko',
        value: 'connection_name',
      },
      {
        text: 'Link Toko',
        value: 'shop_url',
      },
      {
        text: 'Status',
        value: 'status',
      },
      {
        text: 'Aksi',
        value: 'action',
      },
    ]

    const tokenFilter = ref({
      search: '',
      status: {
        id: 1,
        label: 'Semua',
        value: null,
      },
    })

    const statusOptions = [
      {
        id: 1,
        label: 'Semua',
        value: null,
      },
      {
        id: 2,
        label: 'Pending',
        value: 0,
      },
      {
        id: 3,
        label: 'Added',
        value: 2,
      },
    ]

    const tokenPagination = ref({})

    const fetchRequestList = (initial = false) => {
      fetchTokopediaIntegrateRequests({
        limit: tokenPagination.value.itemsPerPage ? tokenPagination.value.itemsPerPage : 10,
        offset: tokenPagination.value.page && !initial ? (tokenPagination.value.page - 1) * tokenPagination.value.itemsPerPage : 0,
      },
      {
        search: tokenFilter.value.search,
        status_id: tokenFilter.value.status.value,
      })
    }

    const confirmChangeStatus = id => {
      // Vue.$dialog({
      //   title: 'Konfirmasi',
      //   body: 'Apakah anda yakin ingin mengubah status token ini?',
      // }).then(result => {
      //   if (result) {
      //     changeStatusTokopediaToken(id).then(() => {
      //       fetchRequestList()
      //     })
      //   }
      // })
      tokopediaConfirmAddDialog.value.open(id)
    }

    const confirmDeleteToken = (id, workspaceId) => {
      Vue.$dialog({
        title: 'Konfirmasi',
        body: 'Apakah anda yakin ingin menolak permintaan token ini?',
      }).then(result => {
        if (result) {
          removeWorkspaceToken(id, workspaceId).then(() => {
            fetchRequestList()
          })
        }
      })
    }

    watch(tokenPagination, () => {
      fetchRequestList()
    })

    onMounted(() => {
      fetchRequestList(true)
    })

    const searchToken = () => {
      fetchRequestList(true)
    }

    const changeStatusToken = () => {
      fetchRequestList(true)
    }

    return {
      headers,
      loadingTokenRequestTokopedia,
      TokenRequestListTokopedia,
      TokenRequestCountTokopedia,
      formatDate,
      // loadingChangeStatusTokopediaToken,
      confirmChangeStatus,
      tokenPagination,
      confirmDeleteToken,
      loadingDeleteToken,
      tokopediaConfirmAddDialog,
      avatarText,
      statusOptions,
      tokenFilter,
      searchToken,
      changeStatusToken,
    }
  },
}
</script>
