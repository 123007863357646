<template>
  <v-dialog
    v-model="isOpen"
    max-width="500px"
  >
    <v-form
      ref="tokopediaForm"
    >
      <v-card>
        <div class="d-flex align-start pa-4">
          <span
            class="text-h6 text-center"
          >
            Konfirmasi Permintaan integrasi Tokopedia
          </span>
          <v-icon
            class="ml-auto mt-1"
            @click="close()"
          >
            {{ icons.mdiClose }}
          </v-icon>
        </div>

        <v-card-text>
          <v-row class="mt-2">
            <v-col cols="12">
              <v-text-field
                v-model="formData.shop_id"
                label="Shop ID"
                outlined
                dense
                flat
                :rules="[required]"
                type="number"
              />
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="primary"
            :loading="loadingChangeStatusTokopediaToken"
            :disabled="loadingChangeStatusTokopediaToken"
            @click="submit"
          >
            Konfirmasi
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>
<script>
import { ref } from '@vue/composition-api'
import { mdiClose } from '@mdi/js'
import { required } from '@core/utils/validation'
import useTokopedia from '@/composables/useTokopedia'

export default {
  setup(props, { emit }) {
    const {
      changeStatusTokopediaToken, loadingChangeStatusTokopediaToken,
    } = useTokopedia()
    const tokopediaForm = ref(null)
    const isOpen = ref(false)
    const formData = ref({})
    const open = id => {
      isOpen.value = true
      formData.value.id = id
    }

    const close = () => {
      isOpen.value = false
      formData.value = {}
    }

    const submit = () => {
      if (tokopediaForm.value.validate()) {
        changeStatusTokopediaToken({
          id: +formData.value.id,
          shop_id: +formData.value.shop_id,
        }).then(() => {
          emit('success')
          close()
        })
      }
    }

    return {
      isOpen,
      open,
      formData,
      close,
      icons: {
        mdiClose,
      },
      required,
      tokopediaForm,
      loadingChangeStatusTokopediaToken,
      submit,
    }
  },
}
</script>
