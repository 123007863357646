import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"max-width":"500px"},model:{value:(_vm.isOpen),callback:function ($$v) {_vm.isOpen=$$v},expression:"isOpen"}},[_c(VForm,{ref:"tokopediaForm"},[_c(VCard,[_c('div',{staticClass:"d-flex align-start pa-4"},[_c('span',{staticClass:"text-h6 text-center"},[_vm._v(" Konfirmasi Permintaan integrasi Tokopedia ")]),_c(VIcon,{staticClass:"ml-auto mt-1",on:{"click":function($event){return _vm.close()}}},[_vm._v(" "+_vm._s(_vm.icons.mdiClose)+" ")])],1),_c(VCardText,[_c(VRow,{staticClass:"mt-2"},[_c(VCol,{attrs:{"cols":"12"}},[_c(VTextField,{attrs:{"label":"Shop ID","outlined":"","dense":"","flat":"","rules":[_vm.required],"type":"number"},model:{value:(_vm.formData.shop_id),callback:function ($$v) {_vm.$set(_vm.formData, "shop_id", $$v)},expression:"formData.shop_id"}})],1)],1)],1),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":"primary","loading":_vm.loadingChangeStatusTokopediaToken,"disabled":_vm.loadingChangeStatusTokopediaToken},on:{"click":_vm.submit}},[_vm._v(" Konfirmasi ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }